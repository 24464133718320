import React, { useEffect, useState } from 'react';
import { approveSuppliedOrder, createSuppliedOrder, getSupplierOder, validateSuppliedOrder } from '../services/suppliedOrdersService';
import { useLocation, useNavigate } from 'react-router-dom';
import './Collecte.css'
import { getProducts } from '../services/produtsService';
import { getUser } from '../services/userService';
import { sendMail } from '../services/mailService';

const Collecte = () => {

    // Get client information from the route location
    const location = useLocation();
    const client = location.state

    const navigate = useNavigate();


    // States
    const [selectedQuality, setSelectedQuality] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState({ C: { product_type: "0", subprice: "0, 00", fk_product: "18", tva_tx: "0" }, G: { product_type: "0", subprice: "0, 00", fk_product: "20", tva_tx: "0" } })

    useEffect(() => {

        getProducts(16).then((res) => {
            const product = {}
            product.product_type = res.data[0].type
            product.subprice = res.data[0].fourn_unitprice
            product.fk_product = res.data[0].id
            product.tva_tx = res.data[0].tva_tx
            setProducts((prevData) => ({ ...prevData, A: product }))
        })

        getProducts(17).then((res) => {
            const product = {}
            product.product_type = res.data[0].type
            product.subprice = res.data[0].fourn_unitprice
            product.fk_product = res.data[0].id
            product.tva_tx = res.data[0].tva_tx
            setProducts((prevData) => ({ ...prevData, B: product }))
        })
    }, [])

    // Handle functions
    const handleCheckboxChange = (quality) => {
        setSelectedQuality(quality);
    };
    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };

    // Function to send supplied order data
    const send = () => {
        setIsLoading(true)
        const product = products[`${selectedQuality}`];
        const data = {
            socid: client.id,
            lines: [
                {
                    ...product,
                    qty: quantity,
                }
            ]
        }
        createSuppliedOrder(data)
            .then((response) => {
                const id = response.data
                validateSuppliedOrder(id)
                    .then(() => {
                        approveSuppliedOrder(id)
                            .then(() => {
                                getSupplierOder(id)
                                    .then((res) => {
                                        const ref = res.data.ref
                                        const productLine = res.data.lines[0]
                                        getUser(res.data.user_author_id)
                                            .then((res) => {

                                                const data = {
                                                    productLibelle: productLine.libelle,
                                                    ref: ref,
                                                    socName: `${client.name} ${client.name_alias !== "" ? `- ${client.name_alias}` : ""}`,
                                                    qty: productLine.qty,
                                                    collector: `${res.data.firstname} - ${res.data.lastname}`,
                                                    date: new Date().toLocaleString(),
                                                    town: client.town

                                                }

                                                const mail = client.email
                                                sendMail(mail, data).then(() => {
                                                    navigate("/")
                                                })

                                            })

                                    }

                                    )
                            })
                            .catch((err) => console.log(err)
                            )
                    })
            })

    }

    return (
        <div className='collecte'>
            <h1>{client.name}</h1>
            <h2>Qualité de l'huile</h2>
            <div>
                <div>
                    <label className="checkboxContainer">
                        <input
                            type="checkbox"
                            value="A"
                            checked={selectedQuality === 'A'}
                            onChange={() => handleCheckboxChange('A')}
                        />
                        <p>A (Très bonne)</p>
                    </label>
                </div>
                <div>
                    <label className="checkboxContainer">
                        <input
                            type="checkbox"
                            value="B"
                            checked={selectedQuality === 'B'}
                            onChange={() => handleCheckboxChange('B')}
                        />
                        <p> B (Moyenne)</p>
                    </label>
                </div>
                <div>
                    <label className="checkboxContainer">
                        <input
                            type="checkbox"
                            value="C"
                            checked={selectedQuality === 'C'}
                            onChange={() => handleCheckboxChange('C')}
                        />
                        <p>

                            C (Déchet)
                        </p>
                    </label>
                </div>
            </div>
            <h2>Graisse Animale</h2>
            <label className="checkboxContainer">
                <input
                    type="checkbox"
                    value="G"
                    checked={selectedQuality === 'G'}
                    onChange={() => handleCheckboxChange('G')}
                />
                <p>

                    Graisse Animale (Déchet)
                </p>
            </label>
            <div className='quantityContainer'>
                <label>
                    Quantité:
                    <input
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                    />
                </label>
                {
                    isLoading ?
                        <div className="spinner"></div>
                        :
                        <button onClick={send}>GO!</button>
                }


            </div>

        </div>

    );
};

export default Collecte;

