import React, { useContext, useEffect } from 'react';
import "./Login.css"
import { login } from '../services/loginService';
import { saveToken } from '../services/localStorageService';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Login = () => {

    // Initialize navigate function for redirection
    const navigate = useNavigate();

    // Get user authentication status from the context
    const { isUser, setIsUser } = useContext(AuthContext);

    // Check if user is authenticated and navigate to the home page
    useEffect(() => {
        (isUser) && navigate("/");
    }, [isUser]);

    // Function to handle form submission
    const sendForm = (e) => {
        e.preventDefault();

        // Prepare data for login request
        const data = { login: e.target.username.value, password: e.target.password.value }

        // Send login request
        login(data)
            .then((res) => {

                // Save the token to local storage and set user authentication status
                saveToken(res.data.success.token);

                // Set isUser State for redirection
                setIsUser(true)
            })
            .catch((err) => console.log(err.response.data.error))
    }

    return (
        <div className='login'>
            <div className='formContainer'>


                <form onSubmit={sendForm}>
                    <div>
                        <label htmlFor="username">Nom d'utilisateur:</label>
                        <input
                            type="text"
                            id="username"
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Mot de passe:</label>
                        <input
                            type="password"
                            id="password"
                        />
                    </div>
                    <button type="submit">Se connecter</button>
                </form>
            </div>
        </div>
    );
};

export default Login;