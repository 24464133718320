import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Client.css'

const Client = () => {
    // Get client information from the route location
    const location = useLocation();
    const client = location.state

    return (
        <div className='container'>

            <div className='client'>

                <h2>{client.name} {client.name_alias !== "" && `- ${client.name_alias}`}</h2>

                <Link to='/collecte' state={client}>
                    <button>Collecter l'huile usagée</button>
                </Link>
                <Link to='/livraison' state={client}>
                    <button>Livrer de l'huile neuve</button>
                </Link>
            </div>
            <Link to='/'>
                <div className='back'>

                    <div className='backContainer'><p>←</p></div><p>Liste des clients</p></div>
            </Link>
        </div>
    );
};

export default Client;