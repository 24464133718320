
import Axios from "./CallerService"

export const validateShipment = (expeditionId) => {
    const data = {
        notrigger: 1
    }
    return Axios.post(`shipments/${expeditionId}/validate`, data)
}
export const closeShipment = (expeditionId) => {
    const data = {
        notrigger: 1
    }
    return Axios.post(`shipments/${expeditionId}/close`, data)
}