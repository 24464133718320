import { useEffect, useState } from "react"
import './List.css'
import { getSupplierOders } from "../services/suppliedOrdersService"
import { getThirdpartie } from "../services/thirdpartie"
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import {
    Printer,
    Table
} from "lucide-react"



const List = () => {

    const [supplierInvoices, setSupplierInvoices] = useState([])

    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
    const [dateFilter, setDateFilter] = useState({ startDate: lastMonth, endDate: lastDayOfLastMonth })
    const [filterApply, setFilterApply] = useState({ startDate: lastMonth, endDate: lastDayOfLastMonth })


    const exportToCSV = (filename) => {
        const csv = unparse(supplierInvoices.filter((invoice) => new Date(invoice.date * 1000) >= filterApply.startDate && new Date(invoice.date * 1000) <= filterApply.endDate));
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, filename);
    };
    useEffect(() => {

        getThirdpartie().then((res) => {
            const frs = res.data.filter((thirdpartie) => { return thirdpartie.fournisseur === "1" })
            let newFrs = {}
            frs.map((fournisseur) => {
                newFrs = { ...newFrs, [`${fournisseur.id}`]: { name: fournisseur.name, alias: fournisseur.name_alias } }
            })
            return newFrs
        }).then((frs) => {
            getSupplierOders().then((res) => {
                const filter = res.data
                    .filter((invoice) =>
                        (invoice.status === "2" || invoice.status === "1")
                        && (invoice?.lines[0]?.ref === "HVD_A" || invoice?.lines[0]?.ref === "HVD_B" || invoice?.lines[0]?.ref === "HVD_C" || invoice?.lines[0]?.ref === "GA")
                    );

                const invoiceWithNameFrs = filter.map((invoice) => {
                    return { ...invoice, frs: frs[invoice.fourn_id].name, aliasFrs: frs[invoice.fourn_id].alias }

                })
                setSupplierInvoices(invoiceWithNameFrs)
            })
        })
    }, []);



    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDate = (e) => {
        const date = new Date(e.target.value)
        date.setHours(0, 0, 0, 0);
        setDateFilter({ ...dateFilter, [e.target.name]: new Date(date) })
    }
    const handleFilter = () => {
        setFilterApply({ ...dateFilter })
    }
    const handlePrint = () => {
        window.print()
    }
    return (
        <div className="list">
            <div className="datesContainer">
                <p>Du</p>
                <input
                    name="startDate"
                    type="date"
                    onChange={handleDate}
                    defaultValue={`${formatDate(lastMonth)}`}
                />
                <p>Au</p>
                <input
                    name="endDate"
                    type="date"
                    onChange={handleDate}
                    defaultValue={`${formatDate(lastDayOfLastMonth)}`}
                />
            </div>
            <div className="buttonContainer">
                <button
                    onClick={handleFilter}
                >
                    Filtrer</button>
                <div
                    className="printButton"
                    onClick={handlePrint}
                >
                    <Printer />

                </div>


                <div onClick={() => exportToCSV('list.csv')}>
                    <Table />
                </div>
            </div>



            {
                supplierInvoices
                    .filter((invoice) => new Date(invoice.date * 1000) >= filterApply.startDate && new Date(invoice.date * 1000) <= filterApply.endDate)
                    .map((invoice, i) => (
                        <div key={i} className="invoiceContainer">
                            {i % 28 === 0 && (

                                <div className="pageBreak">
                                    <div className="datesContainer print">
                                        <p>Du</p>
                                        <p>{filterApply?.startDate?.toLocaleDateString()}</p>
                                        <p>Au</p>
                                        <p>{filterApply?.endDate?.toLocaleDateString()}</p>
                                    </div>
                                </div>

                            )}
                            <div>
                                <div >
                                    <div className="invoiceContainerSoc">
                                        <p>{invoice.ref}</p>
                                        <div className="nameContainer">
                                            <p>{`${invoice.frs} ${invoice.aliasFrs !== "" ? " | " : ""}${invoice.aliasFrs}`}</p>
                                        </div>
                                        <p>{new Date(invoice.date * 1000).toLocaleDateString()}</p>
                                    </div>
                                    <div className="invoiceContainerInfo">
                                        {invoice.lines.map((line) => (
                                            <div key={line.rang} className="lineContainer">
                                                <p>{line.libelle}</p>
                                                <p>{line.qty} L</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))
            }










        </div>
    )
}
export default List
