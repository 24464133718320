


import Axios from "./CallerService"


export const createInvoiceFromOrder = (orderId) => {
    return Axios.post(`/invoices/createfromorder/${orderId}`)
}

