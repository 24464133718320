
import Axios from "./CallerService"
export const createSuppliedOrder = (data) => {
    return Axios.post(`supplierorders`, data)
}

export const validateSuppliedOrder = (id) => {
    return Axios.post(`supplierorders/${id}/validate`)
}

export const approveSuppliedOrder = (id) => {
    return Axios.post(`supplierorders/${id}/approve`)
}

export const getSupplierOders = () => {
    return Axios.get('/supplierorders?limit=-1')

}

export const getSupplierOder = (id) => {
    return Axios.get(`/supplierorders/${id}`)

}