import React from 'react';
import AuthUser from './AuthUser';
import Layout from '../Layout';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Client from '../pages/Client';
import Collecte from '../pages/Collecte';
import Livraison from '../pages/Livraison';
import List from '../pages/List';

const UserRouter = () => {
    return (
        <>
            <Routes>
                <Route element={<AuthUser />}>
                    <Route element={<Layout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/client/:name" element={<Client />} />
                        <Route path="/collecte" element={<Collecte />} />
                        <Route path="/livraison" element={<Livraison />} />
                        <Route path="/liste" element={<List />} />
                    </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>

    );
};

export default UserRouter;