import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createProposal, validateProposal } from '../services/proposalService';
import { createOrderFromProposal, createOrderShipment, validateOrder } from '../services/ordersService';
import { closeShipment, validateShipment } from '../services/shipmentsService';
import { createInvoiceFromOrder } from '../services/invoicesService';
import './Livraison.css'


const Livraison = () => {
    // Retrieve client information from the route location
    const location = useLocation();
    const client = location.state

    const navigate = useNavigate();

    // States
    const [quantity, setQuantity] = useState('');
    const [orderId, setOrderId] = useState()
    const [isLoading, setIsLoading] = useState(false)

    // Handle quantity input change
    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };


    // Main function to initiate the delivery process
    const send = () => {
        setIsLoading(true)
        sendCreateProposal()
            .then((proposalId) => {
                sendValidateProposal(proposalId)
                    .then((proposaValidateId) => {
                        sendCreateOrderFromProposal(proposaValidateId)
                            .then((orderId) => {
                                sendValidateOrder(orderId)
                                    .then((orderValidateId) => {
                                        sendCreateOrderShipment(orderValidateId)
                                            .then((expeditionId) => {
                                                sendValidateShipment(expeditionId)
                                                    .then((expeditionValidateId) => {
                                                        sendCloseShipment(expeditionValidateId)
                                                        sendCreateInvoiceFromdOrder(orderId)
                                                        navigate("/")

                                                    })


                                            })
                                    })



                            })
                    })
            })


    }

    const sendValidateShipment = (expeditionId) => {
        return validateShipment(expeditionId)
            .then((res) => {
                return res.data.id;
            })
            .catch((err) => { console.log(err) })
    }

    const sendValidateProposal = (proposalId) => {
        return validateProposal(proposalId)
            .then((res) => {
                return res.data.id;
            })
            .catch((err) => { console.log(err) })
    }
    const sendValidateOrder = (orderId) => {
        return validateOrder(orderId)
            .then((res) => {
                return res.data.id;
            })
            .catch((err) => { console.log(err) })
    }

    const sendCreateProposal = () => {
        const data = {
            socid: client.id,
            date: Math.floor(Date.now() / 1000),
            lines: [
                {
                    fk_product: "1",
                    fk_product_type: "0",
                    product_type: "0",
                    qty: quantity,
                    tva_tx: "5.5000",
                    subprice: "16.50000000"
                }
            ]
        }
        return createProposal(data)
            .then((res) => {
                return res.data;
            })
            .catch((err) => { console.log(err) })
    }

    // Function to create an order from a proposal
    const sendCreateOrderFromProposal = (proposalId) => {
        return createOrderFromProposal(proposalId)
            .then((res) => {
                setOrderId(res.data.id)
                return res.data.id
            })
            .catch((err) => { console.log(err) })
    }

    // Function to create an order shipment
    const sendCreateOrderShipment = (orderId) => {
        return createOrderShipment(orderId)
            .then((res) => {
                return res.data
            })
            .catch((err) => { console.log(err) })
    }

    // Function to close an order shipment
    const sendCloseShipment = (expeditionId) => {
        return closeShipment(expeditionId)
            .then((res) => {
                return res.data
            })
            .catch((err) => { console.log(err) })
    }

    // Function to create a supplied order
    const sendCreateInvoiceFromdOrder = (orderId) => {
        return createInvoiceFromOrder(orderId)
            .then((res) => {
                return res.data
            })
            .catch((err) => { console.log(err) })
    }

    return (
        <div className='livraison'>
            <h1>{client.name}</h1>
            <div>
                <label>
                    <input
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        placeholder='Quantité de bidons de 10L livrés'
                    />
                </label>
            </div>
            {
                isLoading ?
                    <div className="spinner"></div>
                    :
                    <button onClick={send}>GO!</button>
            }
        </div>
    );
};

export default Livraison;


