
import Axios from "./CallerService"
export const createOrderFromProposal = (proposalId) => {
    return Axios.post(`orders/createfromproposal/${proposalId}`)
}
export const validateOrder = (orderId) => {
    return Axios.post(`orders/${orderId}/validate`)
}
export const createOrderShipment = (orderId) => {
    return Axios.post(`orders/${orderId}/shipment/1`)
}