import { Route, Routes } from "react-router-dom";
import UserRouter from "./router/UserRouter";
import PublicRouter from "./router/PublicRouter";
import './App.css'
import { LogProvider } from "./context/AuthContext";

function App() {
  return (
    <div className="App">
      <LogProvider>
        <Routes>
          <Route path="/*" element={<UserRouter />} />
          <Route path="/auth/*" element={<PublicRouter />} />

        </Routes>
      </LogProvider>
    </div>
  );
}

export default App;
