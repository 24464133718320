import React, { useEffect, useState } from 'react';
import { getThirdpartie } from '../services/thirdpartie';
import { Link } from 'react-router-dom';
import './Home.css'
import CardClient from '../components/CardClient';

const Home = () => {

    // States
    const [clients, setClients] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [filter, setFilter] = useState("");

    useEffect(() => {
        // get clients list and set data to the state
        getThirdpartie().then((res) => {
            const sortedClients = res.data.sort((a, b) => a.name.localeCompare(b.name));
            setClients(sortedClients)
            setIsLoading(false)
        })
    }, []);
    const getFilter = (e) => {
        setFilter(e.target.value.toLowerCase());
    }

    return (

        <>
            {isLoading ? (
                <div className='sipnnerContainer'>
                    <div className="spinner"></div>
                </div>

            )
                :
                (
                    <div className='container home'>
                        <div className='searchContainer'>
                            <input onChange={getFilter} type='text'>
                            </input>
                            <div className='loupeContainer'>
                                <p>🔍</p>
                            </div>

                        </div>
                        {
                            clients.filter((client) => {
                                const textToFilter = (client.name + client.name_alias).toLowerCase()
                                return textToFilter.toLowerCase().includes(filter)
                            }

                            ).map((client) => (
                                <div key={client.id}>
                                    <Link to={`/client/${client.name.toLowerCase().replace(/\s+/g, '-')}`} state={client}>
                                        <CardClient client={client} />
                                    </Link>
                                </div>

                            ))
                        }
                    </div>
                )
            }
        </>
    );
};

export default Home;